import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import {
  CoreOptions,
  PaymentMethodsConfiguration,
} from '@adyen/adyen-web/dist/types/core/types';
import { Spin } from 'antd';
import axios from 'axios';
import ButtonUI from 'components/ui/ButtonUI';
import { useToast } from 'hooks/toast';
import { getProductFromUrl } from 'pages/LandingPages/utils/helpers';
import React, { useEffect, useRef, useState } from 'react';
import ImgBack from '../../../assets/payment/back.png';
import {
  PaymentForm,
  PaymentFormBack,
  PaymentFormCard,
  PaymentFormCardContainer,
  PaymentFormChoosePaymentMethodLabel,
  PaymentFormFail,
  PaymentTitle,
} from './styles';
import { OnPaymentCompletedData } from '@adyen/adyen-web/dist/types/components/types';

interface SessionPaymentDataResponse {
  id: string;
  merchantAccount: string;
  reference: string;
  returnUrl: string;
  sessionData: string;
  message: string;
}

interface SessionPaymentResponse {
  success: boolean;
  statusCode: number;
  totalRecords: number;
  title: string;
  message: string;
  data: SessionPaymentDataResponse;
}

interface CreditCardProps {
  contractNumber: string;
  cpfCnpj: string;
}

const CreditCard = ({ contractNumber, cpfCnpj }: CreditCardProps) => {
  const dropinRef = useRef(null);

  const { addToast } = useToast();

  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [sessionPayment, setSessionPayment] =
    useState<SessionPaymentResponse | null>();
  const [loadingSession, setLoadingSession] = useState(false);
  const [showCreditCardDropIn, setShowCreditCardDropIn] = useState(false);
  const [endDigits, setEndDigits] = useState('');

  const getTokenPayment = (paymentCompletedData: OnPaymentCompletedData) => {
    if (paymentCompletedData.resultCode !== 'Authorised') {
      return;
    }

    setTimeout(async () => {
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_HOST_PAYMENT}/v1/Payment/Checkout/History`,
          {
            shopperReference: cpfCnpj,
          }
        );

        await axios.post(
          `${process.env.REACT_APP_HOST_PAYMENT}/v2/EuroIT/Payments/Methods/Save`,
          {
            contractNumber,
            paymentMethod: 36,
            clientId: cpfCnpj,
            tokenId: data.data.recurringDetailReference,
            creditCardUpdated: true,
          }
        );
        setEndDigits(data.data.cardSummary);
        addToast({
          title: 'Cartão atualizado com sucesso',
          description:
            'O cartão crédito utilizado para pagamento foi atualizado com sucesso.',
          type: 'success',
        });
      } catch {
        setSessionPayment(null);
        addToast({
          title: 'Cartão de crédito não cadastrado',
          description: 'Tente novamente mais tarde.',
          type: 'error',
        });
      } finally {
        setShowCreditCardDropIn(false);
      }
    }, 3500);
  };

  const getPaymentMethodsSession = async () => {
    setLoadingSession(true);

    let merchantAccount = '';

    const productKey = getProductFromUrl();

    switch (productKey) {
      case 'assinecar':
        merchantAccount = 'Whitelabel-AC';
        break;
      case 'signanddrive':
        merchantAccount = 'SignAndDrive';
        break;
      case 'audisignature':
        merchantAccount = 'LMMobilidadeBR';
        break;
      // case 'rentalway':
      //   merchantAccount = 'RentalWay';
      //   break;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_PAYMENT}/v1/Payment/Sessions`,
        {
          merchantAccount,
          amountCharged: '0',
          clientDocumentNumber: cpfCnpj,
        }
      );

      setSessionPayment(response.data);
    } catch (error) {
      backCreditCard();

      addToast({
        title: 'Erro ao obter sessão',
        description: 'Erro ao obter sessão de pagamento cartão de crédito.',
        type: 'error',
      });
    }

    setLoadingSession(false);
  };

  const addCreditCard = async () => {
    getPaymentMethodsSession();

    setShowCreditCardDropIn(true);
  };

  const backCreditCard = () => {
    setSessionPayment(null);
    setShowCreditCardDropIn(false);
  };

  useEffect(() => {
    const initAdyenCheckout = async () => {
      try {
        if (!sessionPayment?.success || !dropinRef.current) {
          return;
        }

        const onError = () => {
          addToast({
            title: 'Erro ao realizar o pagamento',
            description: 'Erro ao fazer pagamento com cartão de crédito.',
            type: 'error',
          });
        };

        const session = {
          id: sessionPayment.data.id,
          sessionData: sessionPayment.data.sessionData,
        };

        const amount = {
          value: 0,
          currency: 'BRL',
        };

        const card: PaymentMethodsConfiguration = {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: false,
        };

        const translations = {
          'pt-BR': {
            'creditCard.success': 'Cartão de crédito cadastrado com sucesso.',
            'error.message.unknown':
              'Cartão não cadastrado. Verifique os dados do cartão e tente novamente.',
            'resultMessages.preauthorized':
              'Estamos atualizando seus dados. Essa ação pode levar alguns segundos. Aguarde nesta tela.',
          },
        };

        const configuration: CoreOptions = {
          locale: 'pt-br',
          environment: process.env.REACT_APP_PAYMENT_ENVIRONMENT,
          clientKey: process.env.REACT_APP_PAYMENT_CLIENT_KEY,
          session,
          paymentMethodsConfiguration: {
            card,
          },
          amount,
          onPaymentCompleted: getTokenPayment,
          onError,
          translations,
        };

        const checkout = await AdyenCheckout(configuration);

        checkout.create('dropin').mount(dropinRef.current);
      } catch (error) {
        console.log(error);
      }
    };

    initAdyenCheckout();
  }, [sessionPayment]);

  useEffect(() => {
    setShowCreditCardDropIn(false);

    const initializePayment = async () => {
      setLoadingInitialData(true);

      try {
        const endDigitsResponse = await axios.post(
          `${process.env.REACT_APP_HOST_PAYMENT}/v2/Payment/CreditCardUsed`,
          {
            contractNumber,
            clientDocumentNumber: cpfCnpj,
          }
        );

        const { data: dataEndDigits } = endDigitsResponse.data;

        setEndDigits(dataEndDigits[0]?.cardNumber || '');
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingInitialData(false);
      }
    };

    initializePayment();
  }, [contractNumber, cpfCnpj]);

  return (
    <>
      <Spin spinning={loadingInitialData}>
        <PaymentForm>
          <PaymentTitle>Alterar cartão de crédito</PaymentTitle>

          {showCreditCardDropIn ? (
            <PaymentFormCardContainer>
              <PaymentFormCard>
                <PaymentFormBack onClick={backCreditCard}>
                  <img src={ImgBack} />
                  Voltar
                </PaymentFormBack>

                <Spin spinning={loadingSession}>
                  <div>
                    <div ref={dropinRef} />
                  </div>
                </Spin>
              </PaymentFormCard>
            </PaymentFormCardContainer>
          ) : (
            <PaymentFormChoosePaymentMethodLabel>
              {/* <h3>Cartão de crédito</h3>
              {endDigits && <p>**** ****** *{endDigits}</p>} */}

              <p>
                O débito acontecerá mensalmente de forma automática no cartão de
                crédito de sua titularidade.
              </p>

              <ButtonUI onClick={addCreditCard}>
                {endDigits ? 'Alterar' : 'Adicionar'} cartão de crédito
              </ButtonUI>
            </PaymentFormChoosePaymentMethodLabel>
          )}
        </PaymentForm>
      </Spin>
    </>
  );
};

export default CreditCard;
