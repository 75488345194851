import React, { useRef, useState } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Modal, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import 'antd/dist/antd.css';

import { ContentModal, ContainerButtons } from './styles';
import Input from '../../components/Input';

import ButtonShared from '../../components/Button';

import getValidationErrors from '../../utils/getValidationErrors';
import logoImg from '../../assets/LM/Fundo_transparente_M.png';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { IState } from '../../store';
import { ILogin } from '../../store/modules/login/types';
import { loginApp } from '../../store/modules/login/actions';
import ForgotPassword from '../ForgotPassword';
import FirstAccess from 'components/FirstAccess';
import ListOfPrograms from 'components/ListOfPrograms';

interface SignInFormData {
  login: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const status = useSelector<IState, ILogin>((state) => state.login);

  const [step, setStep] = useState<
    'login' | 'forgotPassword' | 'firstAccess' | 'listOfPrograms'
  >('login');
  const [isLoading, setIsLoading] = useState(false);
  const [listOfPrograms, setListOfPrograms] = useState([]);

  const handleOk = () => {
    dispatch(loginApp({ status: false }));
  };

  const handleCancel = () => {
    dispatch(loginApp({ status: false }));
  };

  const handleSubmitLogin = (data: SignInFormData) => {
    const schema = Yup.object().shape({
      login: Yup.string().required('E-mail obrigatório'),
      password: Yup.string().required('Senha obrigatória'),
    });

    formRef.current?.setErrors({});
    schema
      .validate(data, {
        abortEarly: false,
      })
      .then(() => {
        setIsLoading(true);
        signIn({
          login: data.login,
          password: data.password,
        })
          .then((response) => {
            if (response.data.token) {
              dispatch(loginApp({ status: false }));
            } else {
              setListOfPrograms(response.data.webSitesUrl);
              setStep('listOfPrograms');
            }
            setIsLoading(false);
          })
          .catch((error) => {
            addToast({
              type: 'error',
              title: 'Erro na autenticação',
              description: error?.response?.data?.messages.join(','),
            });
            setIsLoading(false);
          });
      })
      .catch((error) => {
        if (error instanceof Yup.ValidationError)
          formRef.current?.setErrors(getValidationErrors(error));
      });
  };

  const forgotPasswordModal = () => {
    setStep('forgotPassword');
    dispatch(loginApp({ status: false }));
  };

  const firstAccessModal = () => {
    setStep('firstAccess');
    dispatch(loginApp({ status: false }));
  };

  const handleClosedModalForgotPassword = () => {
    setStep('login');
  };
  const handleClosedModalFirstAccess = () => {
    setStep('login');
  };
  const handleCloseModalListOfPrograms = () => {
    setStep('login');
    dispatch(loginApp({ status: false }));
  };

  switch (step) {
    case 'login':
      return (
        <Modal
          footer={null}
          title=""
          open={status.status}
          onOk={handleOk}
          onCancel={handleCancel}
          style={{ top: 220 }}
        >
          <Spin spinning={isLoading}>
            <ContentModal>
              <img width="350" src={logoImg} alt="Fleet Solutions" />
              <Form ref={formRef} onSubmit={handleSubmitLogin}>
                <Input
                  icon={FiMail}
                  placeholder="E-mail"
                  id="login"
                  name="login"
                  type="text"
                />

                <Input
                  icon={FiLock}
                  placeholder="Senha"
                  id="password"
                  name="password"
                  type="password"
                />
                <ContainerButtons>
                  <ButtonShared type="submit">Entrar</ButtonShared>
                  <a onClick={forgotPasswordModal}>Esqueci minha senha</a>
                  <button
                    className="firstAccessButton"
                    onClick={firstAccessModal}
                  >
                    Primeiro acesso
                  </button>
                </ContainerButtons>
              </Form>
            </ContentModal>
          </Spin>
        </Modal>
      );
    case 'forgotPassword':
      return (
        <ForgotPassword
          isForgotPassword={step === 'forgotPassword'}
          handleClosedModalForgotPassword={handleClosedModalForgotPassword}
        />
      );
    case 'firstAccess':
      return (
        <FirstAccess
          isFirstAccess={step === 'firstAccess'}
          handleClosedModalFirstAccess={handleClosedModalFirstAccess}
        />
      );
    case 'listOfPrograms':
      return (
        <ListOfPrograms
          listOfPrograms={listOfPrograms}
          handleClose={handleCloseModalListOfPrograms}
        />
      );
    default:
      return null;
  }
};

export default SignIn;
