import '@adyen/adyen-web/dist/adyen.css';
import { Spin } from 'antd';
import axios, { AxiosResponse } from 'axios';
import Header from 'components/Header';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { getProductFromUrl } from 'pages/LandingPages/utils/helpers';
import React, { useEffect, useState } from 'react';
import api from 'services/api';
import { default as CreditCardChange } from './components/CreditCard';
import { Container, Select, Text } from './styles';

interface PaymentMethodResponse {
  success: boolean;
  statusCode: number;
  totalRecords: number;
  title: string;
  message: string;
  data: PaymentMethodData;
}

interface PaymentMethodData {
  id: number;
  numeroContrato: string;
  cliente: string;
  clienteCpf: string;
  produto: string;
  canalVenda: string;
  formaPagamentoId: number;
  formaPagamento: string;
  cartaoToken: string;
  cartaoClienteId: string;
}

interface Customer {
  customerCMSId: number;
  customerName: string;
  contractNumbers: string[];
}

const CreditCard = () => {
  const { userId } = useAuth();

  const { addToast } = useToast();

  const [customersOptions, setCustomersOptions] = useState<Customer[]>([]);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [loadingContractNumber, setLoadingContractNumber] = useState(false);
  const [contractNumber, setContractNumber] = useState('0');
  const [canChangePaymentMethod, setCanChangePaymentMethod] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState<string>('');

  const handleChangeContractNumber = (e: any) =>
    setContractNumber(e.target.value);

  const verifyIfCanChangePaymentMethod = async (): Promise<void> => {
    try {
      setLoadingContractNumber(true);

      const response: AxiosResponse<PaymentMethodResponse> = await axios.get(
        `${process.env.REACT_APP_HOST_PAYMENT}/v2/EuroIT/Payments/Methods?contractNumber=${contractNumber}`
      );

      if (response.status !== 500 && response.data.success) {
        setCpfCnpj(response.data.data.clienteCpf);

        setCanChangePaymentMethod(true);

        return;
      }

      setCanChangePaymentMethod(false);
    } catch (error: any) {
      setCanChangePaymentMethod(false);
    } finally {
      setLoadingContractNumber(false);
    }
  };

  const renderOptions = (contractNumbers: string[], customerName: string) =>
    contractNumbers.map((item: string, index: number) => (
      <option key={item + index} value={item}>
        {item + ' - ' + customerName}
      </option>
    ));

  useEffect(() => {
    if (contractNumber === '0') {
      setCanChangePaymentMethod(false);

      return;
    }

    verifyIfCanChangePaymentMethod();
  }, [contractNumber]);

  useEffect(() => {
    const getCustomers = async () => {
      setLoadingCustomer(true);
      try {
        const { data } = await api.get(
          `/crm/reports/${userId}/customers-cms-lm`
        );

        setCustomersOptions(data);
      } catch (error) {
        addToast({
          title: 'Erro',
          description:
            'Tivemos um erro ao localizar seu dados, tente novamente.',
          type: 'error',
        });
      } finally {
        setLoadingCustomer(false);
      }
    };

    getCustomers();
  }, []);

  return (
    <>
      <Header />

      <Container>
        {getProductFromUrl() === 'rentalway' ? (
          <Text>Opção de troca de forma de pagamento em desenvolvimento</Text>
        ) : (
          <Spin spinning={loadingCustomer}>
            <Text>Selecione o contrato desejado</Text>

            <Select onChange={handleChangeContractNumber}>
              <option value="0">Selecione uma opção</option>
              {customersOptions.map((customerOption: Customer) =>
                renderOptions(
                  customerOption.contractNumbers,
                  customerOption.customerName.split(' - ')[1]
                )
              )}
            </Select>

            <Spin spinning={loadingContractNumber}>
              {canChangePaymentMethod ? (
                <CreditCardChange
                  contractNumber={contractNumber}
                  cpfCnpj={cpfCnpj}
                />
              ) : contractNumber !== '0' ? (
                <Text>
                  Contrato selecionado não permite alteração do método de
                  pagamento.
                </Text>
              ) : null}
            </Spin>
          </Spin>
        )}
      </Container>
    </>
  );
};

export default CreditCard;
