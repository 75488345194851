import styled from 'styled-components';

export const PaymentForm = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PaymentFormCardContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PaymentFormCard = styled.div`
  // background-color: #fff;
  // border-radius: 8px;
  // padding: 20px;

  .adyen-checkout__payment-method--selected {
    background-color: #fff;
  }
  @media (min-width: 768px) {
    width: 50%;
  }
`;

export const PaymentFormTitle = styled.h1`
  font-family: ${(props) => props.theme.head};
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 8px;
`;

export const PaymentFormText = styled.p`
  font-family: ${(props) => props.theme.head};
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 32px;
`;

export const PaymentFormBack = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const PaymentFormDropIn = styled.div`
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

export const PaymentFormFail = styled.div`
  color: #ff0000;
  background-color: #fff0f0;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ff0000;
  margin-top: 10px;
  font-weight: bold;
`;

export const PaymentFormChoosePaymentMethodLabel = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  padding: 20px 10px;
  border-radius: 8px;
  background-color: white;
`;

export const PaymentTitle = styled.div`
  font-size: 20px;
  color: #333;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

export const PaymentFormButton = styled.button`
  background-color: blue;
  border-radius: 8px;
  color: white;
  padding: 0 10px;
`;
