import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
`;

export const Select = styled.select`
  min-width: 265px;
  max-width: 100%;
  height: 31px;
  margin: 10px 0;
  font-size: 14px;
  color: #8994a0;
  border: 1px solid #cfd7d9;
  background: #fff;
  border-radius: 18px;
`;

export const Text = styled.p`
  font-size: 20px;
  color: #17536c;
  font-weight: 500;
  margin: 0;
`;
